// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  region: 'US_EAST_1',
  identityPoolId: '',
  userPoolId: 'us-east-1_YLu50ozIk',
  clientId: '2gd9l6cvgta6los4m7mheom0u7',
  cognito_idp_endpoint: 'https://cognito-idp.us-east-1.amazonaws.com/us-east-1_YLu50ozIk',
  cognito_identity_endpoint: '',
  sts_endpoint: '',
  baseUrl_IncomingQueue: 'https://exp-api.proterra.com/exp-iq-qa-launch2020',
  baseUrl_Bus_CCSS_Display: 'https://exp-api.proterra.com/exp-datalake-qa-launch2020',
  baseUrl_RecommendedRun: 'https://exp-api.proterra.com/exp-ccss-qa-launch2020',
  baseUrl_Garage_Tracks: 'https://exp-api.proterra.com/exp-ccss-qa-launch2020',
  baseUrl_CustomerAssets: 'https://exp-api.proterra.com/exp-ams-qa-launch2020',
  // APEX_URL: "http://localhost:9000",
  APEX_URL: 'https://exp-qa-launch2020.proterra.com',
  AGREEMENT_URL : 'https://exp-api.proterra.com/docusign-qa-launch2020/v1'
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
